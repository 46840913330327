export const GoogleIcon = (
  props: React.SVGProps<SVGSVGElement> & { size?: number }
) => (
  <svg
    width={props.size ?? 24}
    height={props.size ?? 24}
    viewBox="-2 -2 24 24"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin"
    {...props}
  >
    <title>Google Icon</title>
    <path d="M4.376 8.068A6 6 0 0 0 4.056 10c0 .734.132 1.437.376 2.086a5.946 5.946 0 0 0 8.57 3.045h.001a5.96 5.96 0 0 0 2.564-3.043H10.22V8.132h9.605a10 10 0 0 1-.044 3.956 10 10 0 0 1-3.52 5.71A9.96 9.96 0 0 1 10 20 9.998 9.998 0 0 1 1.118 5.401 10 10 0 0 1 10 0c2.426 0 4.651.864 6.383 2.302l-3.24 2.652a5.948 5.948 0 0 0-8.767 3.114" />
  </svg>
)
